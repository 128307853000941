<template lang="pug">
  page-layout(:title='$route.name', :loading='loading || !purchaseOrder')
    finance-form(
      :structure='formStructure',
      v-model='purchaseOrder',
      :submit-label='submitLabel',
      modelName='purchaseOrder',
      @save='savePurchaseOrder',
      @cancel='$emit("cancel")',
      @input='$emit("input", $event)',
      :v='$v',
      :loading='loading',
      :saving='saving')
</template>

<script>
import {
  required,
  requiredUnless,
  requiredIf,
  decimal
} from "vuelidate/lib/validators";
import isNil from "ramda/src/isNil";
import isEmpty from "ramda/src/isEmpty";
import clone from "ramda/src/clone";
import FinanceForm from "@/views/finances/Form.vue";
import PageLayout from "@/views/common/PageLayout.vue";
import scrollToError from "@/components/utils/ScrollToError";

const lineItemRequiredValidation = requiredUnless(
  nestedModel =>
    isNil(nestedModel.description) || isEmpty(nestedModel.description)
);

export default {
  components: {
    FinanceForm,
    PageLayout
  },
  props: {
    value: Object,
    loading: Boolean,
    saving: Boolean,
    submitLabel: String
  },
  data() {
    return {
      purchaseOrder: clone(this.value),
      formStructure: {
        resourceName: "Purchase order",
        keyFields: [
          {
            label: "Project",
            name: "project",
            type: "project",
            placeholder: "Select a project",
            required: true
          },
          { label: "Contact", name: "contact", type: "contact", required: true }
        ],
        detailFields: [
          { label: "Number", name: "number", type: "sequence" },
          { label: "Issued date", name: "issuedDate", type: "date" },
          { label: "Delivery date", name: "deliveryDate", type: "date" },
          {
            label: "Currency",
            name: "currency",
            type: "currency",
            required: true
          },
          {
            label: "Exchange rate",
            name: "exchangeRate",
            type: "exchangeRate",
            required: true
          }
        ],
        descriptionField: {
          placeholder:
            "Provide any delivery instructions that may be required...",
          name: "deliveryInstructions"
        },
        additionalFields: [
          [
            {
              label: "Delivery address",
              name: "deliveryAddress",
              type: "text",
              placeholder: "Where do you want this order delivered?"
            }
          ],
          [
            {
              label: "Attention to",
              name: "attentionTo",
              type: "input",
              placeholder: "Who will receive this purchase order"
            },
            { label: "Telephone", name: "telephone", type: "input" }
          ]
        ],
        lineItems: {
          type: "taxable",
          task: false,
          description: true
        }
      }
    };
  },
  methods: {
    savePurchaseOrder() {
      this.$v.purchaseOrder.$touch();
      if (this.$v.purchaseOrder.$invalid) {
        scrollToError(this);
      } else {
        this.$emit("save");
      }
    }
  },
  validations: {
    purchaseOrder: {
      project: {
        required
      },
      contact: {
        required
      },
      lineItems: {
        $each: {
          description: {
            required: requiredIf(
              nestedModel =>
                !(isNil(nestedModel.item) || isEmpty(nestedModel.item)) ||
                !(
                  isNil(nestedModel.quantity) || isEmpty(nestedModel.quantity)
                ) ||
                !(
                  isNil(nestedModel.unitCost) || isEmpty(nestedModel.unitCost)
                ) ||
                !(isNil(nestedModel.account) || isEmpty(nestedModel.account)) ||
                !(isNil(nestedModel.taxRate) || isEmpty(nestedModel.taxRate))
            )
          },
          quantity: {
            required: lineItemRequiredValidation,
            decimal
          },
          unitCost: {
            required: lineItemRequiredValidation,
            decimal
          },
          account: {
            required: lineItemRequiredValidation
          },
          taxRate: {
            required: lineItemRequiredValidation
          }
        }
      }
    }
  },
  watch: {
    value(newValue) {
      this.purchaseOrder = clone(newValue);
    }
  }
};
</script>
