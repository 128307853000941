import path from "ramda/src/path";

function idOrNil(propPath, lineItem) {
  const id = path(propPath, lineItem);
  if (id === undefined) {
    return null;
  }

  return { id };
}

export default lineItem => ({
  id: lineItem.id,
  item: idOrNil(["item", "id"], lineItem),
  description: lineItem.description,
  quantity: lineItem.quantity,
  unitCost: lineItem.unitCost,
  account: {
    id: path(["account", "id"], lineItem)
  },
  taxRate: {
    id: path(["taxRate", "id"], lineItem)
  }
});
