<template lang="pug">
  .flex.flex-row.h-8.m-auto.justify-center.tabs.menu.mb-8
    a.item.secondary-nav-item.h-full(:href="`/projects/${projectId}#/finances/overview`")
      app-icon.mr-2(icon="finance")
      p Overview

    a.item.secondary-nav-item.h-full.ml-12(:href="`/projects/${projectId}#/finances/change_orders`" :class="{'active': activeTab === 'changeOrder' }")
      app-icon.mr-2(icon="changeOrder")
      p Change orders

    a.item.secondary-nav-item.h-full.ml-12(:href="`/projects/${projectId}#/finances/invoices`" :class="{'active': activeTab === 'invoice' }")
      app-icon.mr-2(icon="invoice")
      p Invoices

    a.item.secondary-nav-item.h-full.ml-12(:href="`/projects/${projectId}#/finances/purchase_orders`" :class="{'active': activeTab === 'purchaseOrder' }")
      app-icon.mr-2(icon="purchaseOrder")
      p Purchase orders

    a.item.secondary-nav-item.h-full.ml-12(:href="`/projects/${projectId}#/finances/bills`" :class="{'active': activeTab === 'bill' }")
      app-icon.mr-2(icon="bill")
      p Bills

    a.item.secondary-nav-item.h-full.ml-12(:href="`/projects/${projectId}#/finances/timesheet_entries`" :class="{'active': activeTab === 'timesheetEntry' }")
      app-icon.mr-2(icon="timesheetEntry")
      p Timesheet entries
</template>
<script>
export default {
  props: {
    activeTab: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    }
  }
};
</script>
