import reject from "ramda/src/reject";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import lineItemFilter from "./TaxLineItemFilter";

export default purchaseOrder => ({
  contact: {
    id: purchaseOrder.contact.id
  },
  project: {
    id: purchaseOrder.project.id
  },
  currency: {
    id: purchaseOrder.currency.id
  },
  number: purchaseOrder.number,
  issuedDate: purchaseOrder.issuedDate,
  deliveryDate: purchaseOrder.deliveryDate,
  exchangeRate: purchaseOrder.exchangeRate,
  deliveryInstructions: purchaseOrder.deliveryInstructions,
  deliveryAddress: purchaseOrder.deliveryAddress,
  attentionTo: purchaseOrder.attentionTo,
  telephone: purchaseOrder.telephone,
  lineItems: reject(
    li => isEmpty(li.description) || isNil(li.description),
    purchaseOrder.lineItems.map(li => lineItemFilter(li))
  )
});
